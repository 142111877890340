








































































import { API_PERMISSION_ROLE } from "@/configs/Apis";
import {
  hasPermission,
  isEmptyOrWhiteSpace,
  showError,
  showSuccess,
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Component, Vue } from "vue-property-decorator";
@Component
export default class PermissionSetting extends Vue {
  roles: Array<any> = [];
  allPermissions: Array<any> = [
    {name: "PATENT", label: "专利管理"},
    {name: "CUSTOMER", label: "客户管理"},
    {name: "SETTING", label: "系统设置"},
    {name: "BIND_CUSTOMER", label: "案件关联客户"},
    {name: "CREATE_ACTIVITY", label: "创建对内时限任务"},
    {name: "ASSIGN_ACTIVITY", label: "指派对内时限任务"},
    {name: "PATENT_FILE", label: "对外专利事项"},
    {name: "PATENT_FEE", label: "专利费用管理"},
  ];

  isEditing = false;

  created() {
    this.loadData();
  }

  handlePermissionChange(role: any) {
    role.permissionList = role.permissionEnabled
      ? this.allPermissions.map((permission: any) => permission.name)
      : [];
    role.isIndeterminate = false;
  }

  handleCheckedPermissionChange(role: any) {
    const checkedCount = role.permissionList.length;
    role.permissionEnabled = checkedCount === this.allPermissions.length;
    role.isIndeterminate = checkedCount > 0 && checkedCount < this.allPermissions.length;
  }

  loadData() {
    this.roles = [];
    Ajax.queryData(API_PERMISSION_ROLE, {}, "GET")
      .then((data) => {
        (data || []).forEach((role: any) => {
          role.isIndeterminate = false;
          role.permissionEnabled = false;
          if (isEmptyOrWhiteSpace(role.permissions)) {
            role.permissionList = [];
          } else {
            role.permissionList = JSON.parse(role.permissions) || [];
            if (role.permissionList.length > 0) {
              role.permissionEnabled = true;
            }
          }
          this.roles.push(role);
        });
      })
      .catch(() => "");
  }

  hasPerm(checkedPerm: string) {
    return hasPermission(this.$store.getters["user/user"] || {}, checkedPerm);
  }

  editInfo() {
    this.isEditing = true;
  }

  cancelEdit() {
    this.isEditing = false;
    this.loadData();
  }

  submitData() {
    const postData = JSON.parse(JSON.stringify(this.roles));
    postData.forEach((role: any) => {
      role.permissions = JSON.stringify(role.permissionList || []);
    });
    Ajax.saveData(API_PERMISSION_ROLE, postData, "PUT")
      .then(() => {
        showSuccess("保存成功");
      })
      .catch(({ message }) => showError(message));
  }
}
